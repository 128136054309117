<!--
	作者：jyl
	时间：2017-07-26
	描述：商户信息管理页面
-->
<template>
  <div class="page1">
    <!--主体内容-->
    <div class="content">
      <!--搜索条件区域-->
      <div class="searchWrapper" @keydown.enter.prevent="searchData">
        <el-form :inline="true" label-position="right" :model="formInline" class="demo-form-inline">
          <el-form-item label="资产名称">
            <el-input
              :maxlength="20"
              size="12"
              v-model.trim="formInline.equipmentName"
              placeholder="输入资产名称"
            ></el-input>
          </el-form-item>
          <el-form-item label="资产编码">
            <el-input
              :maxlength="20"
              size="12"
              v-model.trim="formInline.equipmentCode"
              placeholder="输入资产编码"
            ></el-input>
          </el-form-item>
          <el-form-item :label="$t('searchModule.Merchant_Name')">
            <el-select v-model.trim="formInline.operationId" filterable size="15">
              <el-option label="全部" value=""></el-option>
              <el-option
                :label="value.operationName"
                :value="value.operationId"
                :key="value.operationId"
                v-for="value in tenantList"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-button
              type="primary"
              icon="el-icon-search"
              @click="
                page = 1;
                searchData();
              "
              :loading="loading"
              >{{ $t('button.search') }}</el-button
            >
            <router-link :to="{ path: '/equipmentAE' }">
              <el-button v-if="$route.meta.authority.button.add" type="primary" icon="el-icon-plus"
                >{{ $t('button.addto') }}</el-button
              >
            </router-link>
          </el-form-item>
        </el-form>
      </div>
      <!--列表区域-->
      <div class="tableWrapper bgFFF paddingB10">
        <el-table
          header-cell-class-name="header-call-style"
          v-loading="loading"
          :data="tableData"
          style="width: 100%; font-size: 12px"
        >
          <el-table-column
            type="index"
            :label="$t('list.index')"
            width="70"
            :index="indexMethod"
          ></el-table-column>
          <el-table-column
            :prop="item.prop"
            :label="item.label"
            :width="item.width"
            align="center"
            v-for="item in tableCols"
            :key="item.prop"
            :formatter="item.formatter"
          ></el-table-column>
          <el-table-column
            label="操作"
            width="140"
            align="center"
            v-if="$route.meta.authority.button.edit || $route.meta.authority.button.delete"
          >
            <template slot-scope="scope">
              <el-dropdown @command="handleCommand($event, scope.row)">
                <el-button type="text" size="small" style="padding: 0"
                  >操作<i class="el-icon-arrow-down"
                /></el-button>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item command="b" v-if="$route.meta.authority.button.edit"
                    >编辑</el-dropdown-item
                  >
                  <el-dropdown-item command="c" v-if="$route.meta.authority.button.delete"
                    >删除</el-dropdown-item
                  >
                </el-dropdown-menu>
              </el-dropdown>
              <!-- <el-button type="text"  @click="$router.push({path: '/equipmentAE', query: scope.row})">修改</el-button>
              <el-button type="text" @click="deleteEquipment(scope.row)">删除</el-button> -->
            </template>
          </el-table-column>
        </el-table>
        <!--分页器-->
        <div class="pagerWrapper">
          <div class="block">
            <el-pagination
              @current-change="handleCurrentChange"
              :current-page="page"
              :page-size="pageSize"
              layout="total, prev, pager, next, jumper"
              :total="total"
            >
            </el-pagination>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "equipmentList",
  data() {
    return {
      index: 0,
      equipmentStateList: [],
      total: 0,
      pageSize: 15,
      page: 1,
      loading: false,
      detailFlag: false,
      areaList: [],
      parkList: [],
      berthList: [],
      tenantList: [],
      tableCols: [
        {
          prop: "equipmentName",
          label: "资产名称",
          width: "",
        },
        {
          prop: "equipmentTypeName",
          label: "资产类型",
          width: "",
        },
        {
          prop: "equipmentCode",
          label: "资产编码",
          width: "",
        },
        {
          prop: "operationName",
          label: this.$t("list.Merchant_Name"),
          width: "",
        },
      ],
      tableData: [],
      formInline: {
        page: 1,
        pageSize: 15,
        equipmentName: "",
        equipmentCode: "",
        equipmentType: 1,
        operationId: "",
      },
    };
  },
  methods: {
    handleCommand(cmd, data) {
      if (cmd == "b") {
        this.$router.push({
          path: "/equipmentAE",
          query: data,
        });
      }
      if (cmd == "c") {
        this.deleteEquipment(data);
      }
    },
    /* 商户名称的下拉框 */
    getTenantList() {
      this.$axios.get("/acb/2.0/operation/nameList").then((res) => {
        if (res.state == 0) {
          this.tenantList = res.value;
        } else {
          this.$alert(res.desc, this.$t('pop_up.Tips'), {
            confirmButtonText: this.$t('pop_up.Determine'),
          });
        }
      });
    },
    indexMethod(index) {
      return index + 1;
    },
    // 获取设备状态
    getOptions() {
      let url = "/acb/2.0/equipment/getAddOption";
      this.$axios
        .get(url, {
          data: this.formInline,
        })
        .then((res) => {
          this.loading = false;
          if (res.state == 0) {
          } else {
            this.$alert(res.desc, this.$t('pop_up.Tips'), {
              confirmButtonText: this.$t('pop_up.Determine'),
            });
          }
        });
    },
    // 分页处理
    handleCurrentChange(val) {
      this.page = val;
      this.searchData();
    },
    deleteEquipment({ equipmentId }) {
      this.$confirm("此操作将删除设备, 是否继续?", this.$t('pop_up.Tips'), {
        confirmButtonText: this.$t('pop_up.Determine'),
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          let url = "/acb/2.0/equipment/delete/" + equipmentId;
          this.$axios.post(url).then((res) => {
            if (res.state == 0) {
              this.$alert("删除成功！", this.$t('pop_up.Tips'), {
                confirmButtonText: this.$t('pop_up.Determine'),
              });
              this.searchData();
            } else {
              this.$alert(res.desc, this.$t('pop_up.Tips'), {
                confirmButtonText: this.$t('pop_up.Determine'),
              });
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消操作",
          });
        });
    },
    // 搜索
    searchData() {
      this.tableData = [];
      this.loading = true;
      let url = "/acb/2.0/equipment/list";
      this.$axios
        .get(url, {
          data: {
            ...this.formInline,
            page: this.page,
          },
        })
        .then((res) => {
          this.loading = false;
          if (res.state == 0) {
            this.tableData = res.value.list;
            this.total = res.value.total * 1;
          } else {
            this.$alert(res.desc, this.$t('pop_up.Tips'), {
              confirmButtonText: this.$t('pop_up.Determine'),
            });
          }
        });
    },
  },
  components: {},
  activated() {
    this.searchData();
  },
  created() {
    this.getOptions();
    this.getTenantList();
  },
  mounted() {
    this.searchData();
  },
  computed: {},
};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="stylus" rel="stylesheet/stylus">
.content

  overflow hidden
  .searchWrapper
    overflow hidden
    padding 22px 22px 0
    margin-bottom 20px
  .pagerWrapper
    text-align right
    margin-top 28px
    font-size 12px
    .export
      font-size 12px
      .iconfont
        margin-right 0
.detail
  width 61.4%
  height 82.9%
  position absolute
  top 8.6%
  left 20.1%
  background #FFFFFF
  box-shadow 0 0 4px 1px rgba(128, 145, 165, 0.3)
  border-radius 3px
  z-index 1000
  box-sizing border-box
  padding 20px 0
  .title
    position absolute
    background #3F4A56
    border-radius 3px 3px 0 0
    color #fff
    width 100%
    top 0
    left 0
    height 40px
    line-height 40px
    font-size 14px
    padding 0 12px
    box-sizing inherit
    z-index 100
    .el-icon-close
      float right
      margin-top 14px
  .info
    margin-top 20px
    padding 2.1%
    height 100%
    overflow auto
    box-sizing inherit
.mask
  background rgba(49, 53, 65, 0.6)
  position fixed
  top 0
  bottom 0
  left 0
  right 0
  z-index 999
</style>
